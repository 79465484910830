import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit'

import login, { LoginSlice } from '../../state/slices/login'
import ui, { UISlice } from '../../state/slices/ui'

const rootReducer = combineReducers({
  login,
  ui,
})

export interface StateSchema {
  login: LoginSlice
  ui: UISlice
}

export const configurator = () => {
  return configureStore({
    devTools: true,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: true,
        serializableCheck: false,
        immutableCheck: {
          warnAfter: 128,
        },
      }),
  })
}

export type Store = ReturnType<typeof configurator>
export type Dispatch = Store['dispatch']

export default configurator()
