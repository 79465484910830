import { Avatar, Button } from '@clout-team/web-components'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { requestCodeByEmail } from '../../shared/requests'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import { useLoginActions } from '../../state/slices/login'
import Heading from '../../shared/ui/Heading/Heading'
import Input from '../../shared/ui/Input/Input'
import './JoinCompany.scss'
import { trimPaste } from '../../shared/lib/functions'
import { useTranslation } from 'react-i18next'
import { isValidEmail } from '../../shared/lib/isValidEmail'

const JoinCompany: React.FC = () => {
  const { t } = useTranslation()

  const [mailValue, setMailValue] = useState<string>('')
  const [isError, setIsError] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(true)
  const companyName = useAppSelector((state) => state.login.company_name)
  const companyAvatar = useAppSelector((state) => state.login.company_avatar)
  const isLoading = useAppSelector((state) => state.login.is_loading)
  const dispatch = useDispatch()

  const { setIsLoading, setMail } = useLoginActions()

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim()
    setIsError(false)
    setMailValue(value)
    if (isValidEmail(value)) {
      setIsValid(true)
      return
    }
    setIsValid(false)
  }

  const onContinueHandler = () => {
    if (isValidEmail(mailValue) && isValid && !isError) {
      setIsLoading(true)
      setMail(mailValue)

      dispatch(
        requestCodeByEmail({
          email: mailValue,
        }),
      )
    } else {
      setIsError(true)
    }
  }

  const onBlurHandler = () => {
    if (isValid || mailValue?.trim()?.length === 0) {
      setIsError(false)
      return
    }
    setIsError(true)
  }

  const onKeyDownHandler = (e: any) => {
    if (e.code === 'Enter') {
      onContinueHandler()
    }
  }

  const renderTitle = useCallback(() => {
    const title = t('auth.new.join_comp.title')
    return {
      __html: title.replace(
        '{__name__}',
        `<span class="j_company_name">${companyName}</span>`,
      ),
    }
  }, [t, companyName])

  const onPasteHandle = (e: any) => {
    trimPaste(e)
    setTimeout(() => onChangeHandler(e), 100)
  }

  return (
    <div className={'j_join'}>
      <Avatar
        className={'j_join__avatar'}
        preview={false}
        type='company'
        size='72'
        src={companyAvatar ? companyAvatar : ''}
      />
      <Heading
        title={<span dangerouslySetInnerHTML={renderTitle()} />}
        description={t('auth.new.join_company.description')}
      />
      <div className={'j_join__bottom_wrapper'}>
        <Input
          type={'email'}
          className={'j_join__input'}
          title={t('auth.new.main.email_address')}
          placeholder={t('auth.new.main.enter_email')}
          value={mailValue}
          onChange={onChangeHandler}
          fullWidth
          errorMessage={isError ? t('auth.new.main.error') : ''}
          onBlurHandler={onBlurHandler}
          onKeyDown={onKeyDownHandler}
          onPaste={onPasteHandle}
          focus
        />
        <Button
          size={'lg'}
          loading={isLoading}
          disabled={isError || mailValue === ''}
          fullWidth
          onClick={onContinueHandler}
        >
          {t('auth.new.main.continue_btn')}
        </Button>
      </div>
    </div>
  )
}

export default JoinCompany
