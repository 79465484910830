import './Promo.scss'
import { Slider } from '@clout-team/web-components'
import React, { useMemo, useState } from 'react'
import LanguageSwitch from '../../shared/ui/LanguageSwitch/LanguageSwitch'
import Logo from '../../shared/ui/Logo/Logo'
import classNames from 'classnames'
import { useLoginActions } from '../../state/slices/login'
import ButtonUI from '../../shared/ui/ButtonUI/ButtonUI'
import Promo1Image from '../../assets/images/promo1.png'
import Promo2Image from '../../assets/images/promo2.png'
import Promo3Image from '../../assets/images/promo3.png'
import { useTranslation } from 'react-i18next'
import { BRAND_NAME, BRAND_NAME_RU } from '../../shared/const/constants'

const Promo = () => {
  const { t, i18n } = useTranslation()
  const { setStep } = useLoginActions()

  const [activeSlide, setActiveSlide] = useState(0)

  const sliderItems = useMemo(() => {
    const getWrapper = (title: string, description: string, img: any) => (
      <div className={'pr_slider_item'}>
        <img className='pr_slider_image' src={img} />
        <div className='pr_slider_info'>
          <p className='pr_slider_title'>{title}</p>
          <p className='pr_slider_description'>{description}</p>
        </div>
      </div>
    )

    return [
      getWrapper(t('promo.slider.1.title'), t('promo.slider.1.description'), Promo1Image),
      getWrapper(t('promo.slider.2.title'), t('promo.slider.2.description'), Promo2Image),
      getWrapper(t('promo.slider.3.title'), t('promo.slider.3.description'), Promo3Image),
    ]
  }, [t])

  const onOpenLoginPage = () => {
    setStep('START')
  }

  return (
    <div className='pr_container'>
      <div className={'pr_header'}>
        <LanguageSwitch />
        <Logo logo={i18n.language === 'ru' ? BRAND_NAME_RU : BRAND_NAME} />
      </div>
      <Slider
        className={'pr-slider'}
        headerClassName={'pr-slider_header'}
        items={sliderItems}
        titles={['', '', '']}
        onChangeItem={(index) => setActiveSlide(index)}
      />
      <div className='pr_slider_position'>
        <div className={classNames(activeSlide === 0 && 'pr_active_slide')} />
        <div className={classNames(activeSlide === 1 && 'pr_active_slide')} />
        <div className={classNames(activeSlide === 2 && 'pr_active_slide')} />
      </div>

      <div className='pr_footer'>
        <ButtonUI size={'xlg'} fullWidth onClick={onOpenLoginPage}>
          {t('promo.start')}
        </ButtonUI>
      </div>
    </div>
  )
}

export default Promo
