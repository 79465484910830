import { Button, CodeInput, config } from '@clout-team/web-components'
import React, { useEffect, useState } from 'react'
import {
  checkCodeByEmail,
  confirmCodeByEmail,
  IConfirmCode,
  requestCodeByEmail,
} from '../../shared/requests'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import { useLoginActions } from '../../state/slices/login'
import Heading from '../../shared/ui/Heading/Heading'
import './CodeStep.scss'
import { useAppDispatch } from '../../shared/hooks/useAppDispatch'
import { useTranslation } from 'react-i18next'

const TIME = 180
let timeOut: ReturnType<typeof setTimeout>

const CodeStepWeb: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { setError, setStep, setWorkspaceUserHash, setIsLoading } = useLoginActions()

  const email = useAppSelector((state) => state.login.mail ?? '')
  const error = useAppSelector((state) => state.login.error)
  const isLoading = useAppSelector((state) => state.login.is_loading)
  const inviteHash = useAppSelector((state) => state.login.invite_hash)

  const [timeState, setTimeState] = useState<number>(TIME)
  const [isClear, setIsClear] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [code, setCode] = useState<any>('')

  const formatTimer = (seconds: number) => {
    if (seconds > 0) {
      const min = Math.floor(seconds / 60)
      const sec = seconds - min * 60
      let secString = `${sec}`

      if (sec < 10) {
        secString = `0${sec}`
      } else if (sec < 1) {
        secString = `00`
      }

      return `${min}:${secString}`
    } else {
      return ``
    }
  }

  const onClickForRepeatHandler = () => {
    if (timeState === 0) {
      setIsClear(true)
      dispatch(requestCodeByEmail({ email: email }))
      setTimeState(TIME)
    }
  }

  const onChangeEmailHandler = () => {
    setError(null)
    setStep('START')
    setWorkspaceUserHash(null)
  }

  const onCompleteHandler = (value: string) => {
    clearTimeout(timeOut)

    // TODO проверить компонент, дважды отрабатывает, для этого обернул таймаутом
    timeOut = setTimeout(async () => {
      setIsLoading(true)
      setError(null)

      const props: IConfirmCode = { email: email, code: value }
      if (inviteHash) props.hash_invite = inviteHash

      let payload
      dispatch(checkCodeByEmail(props)).then((res) => {
        if (res.meta.requestStatus === 'fulfilled' && res.payload?.response) {
          payload = res.payload
          dispatch(confirmCodeByEmail(payload))
        } else {
          setIsClear(true)
        }
      })
    }, 100)
  }

  const onChangeHandler = (value: string) => {
    setCode(value)
    setIsClear(false)
    if (value.length === 6) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (timeState > 0) {
      timeout = setTimeout(() => {
        setTimeState((prev) => --prev)
      }, 1000)
    }
    return () => {
      if (timeout !== undefined) {
        clearTimeout(timeout)
      }
    }
  }, [timeState])

  return (
    <div className={'cd_code'}>
      <Heading
        title={`${t('auth.new.codestep.title')}📩`}
        description={t('auth.new.codestep.description')?.replace('{__main__}', email)}
      />
      <div className={'cd_code__bottom'}>
        <p className={'cd_code__bottom_description'}>
          {t('auth.new.codestep.code_description')}
        </p>

        <CodeInput
          onValueChange={onChangeHandler}
          clear={isClear}
          type='digits'
          onComplete={onCompleteHandler}
          length={6}
        />

        {error !== null && <p className={'cd_code__error'}>{t(error)}</p>}

        <div className={'cd_code__bottom_buttons'}>
          <Button
            size={'lg'}
            className={'cd_code__resend_btn'}
            color='white'
            disabled={timeState !== 0}
            fullWidth
            onClick={onClickForRepeatHandler}
          >
            {timeState !== 0
              ? `${t('auth.new.codestep.send_code_again')} (${formatTimer(timeState)})`
              : `${t('auth.new.codestep.send_code_again')}`}
          </Button>
          <p onClick={onChangeEmailHandler} className={'cd_code__link'}>
            {t('auth.button.change_email')}
          </p>
        </div>
        <Button
          loading={isLoading}
          disabled={isDisabled}
          size={'lg'}
          fullWidth
          onClick={() => onCompleteHandler(code)}
        >
          {t('auth.new.main.continue_btn')}
        </Button>
      </div>
    </div>
  )
}

export default CodeStepWeb
