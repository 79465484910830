import React from 'react'
import './CardContact.scss'
import { Avatar, Checkbox, config, ListItem, Tag, Text } from '@clout-team/web-components'
import classNames from 'classnames'
import { CheckmarkCircleFilled } from '@clout-team/icons/react'
import { useTranslation } from 'react-i18next'

interface CardContactProps {
  isSelected?: boolean
  title: string
  description: string
  avatar?: string
  onClick?: () => void
  isFriend?: boolean
  typeView?: 'list' | 'card'
}

const CardContact = (props: CardContactProps) => {
  const {
    isSelected = false,
    title,
    description,
    avatar = '',
    onClick = () => {},
    isFriend = false,
    typeView = 'card',
  } = props

  const { t } = useTranslation()

  if (config.IS_MOBILE) {
    if (typeView === 'card') {
      return (
        <div
          onClick={onClick}
          className={classNames(
            'card-contact-component',
            'card-contact-component_card-view',
            {
              'card-contact-selected': isSelected,
              'card-contact-friend': isFriend,
            },
          )}
        >
          <div className='card-contact-avatar'>
            <Avatar
              className='card-contact-avatar-component'
              size='full'
              type='private'
              src={avatar}
            />
            {isFriend ? (
              <Tag icon={<CheckmarkCircleFilled />} className={'card-contact-tag'}>
                {t('auth.already_added')}
              </Tag>
            ) : (
              <Checkbox
                className='card-contact-checkbox'
                isChecked={isSelected}
                onMouseDown={onClick}
              />
            )}
          </div>
          <div className='card-contact-content'>
            <div className='card-contact-title'>
              <Text type='body-bold'>{title}</Text>
            </div>
          </div>
        </div>
      )
    }

    return (
      <ListItem
        checkbox
        isChecked={isSelected}
        onClick={onClick}
        className={'card-contact-component_view-list'}
        avatar={{ size: '48', type: 'private', src: avatar }}
        title={title}
        iconRight={<Checkbox isChecked={isSelected} />}
        description={description}
      />
    )
  }

  return (
    <div
      className={classNames('card-contact-component', {
        'card-contact-selected': isSelected,
        'card-contact-friend': isFriend,
      })}
    >
      <div className='card-contact-avatar' onClick={onClick}>
        <Avatar
          className='card-contact-avatar-component'
          size='full'
          type='private'
          src={avatar}
        />
        {isFriend ? (
          <Tag icon={<CheckmarkCircleFilled />} className={'card-contact-tag'}>
            {t('auth.already_added')}
          </Tag>
        ) : (
          <Checkbox
            onMouseDown={onClick}
            className='card-contact-checkbox'
            isChecked={isSelected}
          />
        )}
      </div>
      <div className='card-contact-content'>
        <div className='card-contact-title'>
          <Text type='body-bold'>{title}</Text>
        </div>
        <div className='card-contact-position'>
          <Text type='secondary' size='footnote'>
            {description}
          </Text>
        </div>
      </div>
    </div>
  )
}

export default CardContact
