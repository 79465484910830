import React from "react";
import {ErrorPage} from "@clout-team/web-components";

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error: any) {
    return {hasError: true};
  }

  componentDidCatch(error: any, errorInfo: any) {

  }

  render() {
    //@ts-ignore
    if (this.state.hasError) {
      return <ErrorPage/>
    }
    //@ts-ignore
    return this.props.children;
  }
}

export default ErrorBoundary;
