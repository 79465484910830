import React from 'react'
import { Button } from '@clout-team/web-components'
import classNames from 'classnames'
import './ButtonUI.scss'

interface ButtonProps {}

const ButtonUI = (props: any) => {
  return <Button  {...props} className={classNames('button-ui', props.className)}/>
}

export default ButtonUI