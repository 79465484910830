import { URL } from '../api/urls'

export const uploadSignupAvatar = async (hash: string, file: any, type: 'INVITED' | 'REG') => {
  return await new Promise(async (res: (data: any[]) => any, rej: any) => {
    const formData = new FormData()
    formData.append('file', file)
    const xhr = new XMLHttpRequest()

    if (type === 'REG') {
      xhr.open('POST', `${URL.API}/login/create/${hash}/avatar`, true)
    } else {
      xhr.open('POST', `${URL.API}/invite/${hash}/avatar`, true)
    }

    xhr.responseType = 'json'
    xhr.onload = function (e: any) {
      try {
        res(e.target.response.response)
      } catch (e) {
        console.log('e', e)
        rej(e)
      }
    }
    xhr.send(formData)
  })
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const readFileAsync = async (file: any) => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e: any) => {
      resolve(e.target.result)
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}
