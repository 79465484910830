import React  from 'react'
import "./Finish.scss"
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import ChevronRight from '@clout-team/icons/react/ChevronRight'
import {chatURL, contactsURL, meetURL, spaceURL} from "../../shared/lib/urls";
import {useTranslation} from "react-i18next";
import {config} from "@clout-team/web-components";

const Finish = () => {
  const {t} = useTranslation()

  const name = useAppSelector((state) => state.login.name)
  const surname = useAppSelector((state) => state.login.surname)
  const company = useAppSelector((state) => state.login.company_name)

  return (
    <>
      <div>
        <div className={'f_heading_wrapper'}>
          <h2 className={'f_heading_main'}>{`${t('auth.lenza.final.title')} ${name + ' ' + surname} ✨`}</h2>
          <p className={'f_description_main'}>{t('auth.lenza.final.description')}</p>
        </div>
        <div className={'f_wrapper'}>
          <a href={chatURL(company)} className={'f_card'}>
            <img className={'f_img'} src={`https://${config.DOMAIN_AVATARS}/theme/white_soft/app/chat.png`} alt='logo' />
            <span className={'f_container'}>
              <p className={'f_heading'}>Lenza Chats</p>
              <p className={'f_description'}>{t('auth.description.instant_chat_software')}</p>
            </span>
            <ChevronRight className={'f_icon'} />
          </a>
          <a href={spaceURL()} className={'f_card'}>
            <img className={'f_img'} src={`https://${config.DOMAIN_AVATARS}/theme/white_soft/app/space.png`} alt='logo' />
            <span className={'f_container'}>
              <p className={'f_heading'}>Lenza Spaces</p>
              <p className={'f_description'}>{t('auth.description.project_and_task')}</p>
            </span>
            <ChevronRight className={'f_icon'} />
          </a>
          <a href={meetURL()} className={'f_card'}>
            <img className={'f_img'} src={`https://${config.DOMAIN_AVATARS}/theme/white_soft/app/call.png`} alt='logo' />
            <span className={'f_container'}>
              <p className={'f_heading'}>Lenza Meet</p>
              <p className={'f_description'}>{t('auth.description.video_conferences_without')}</p>
            </span>
            <ChevronRight className={'f_icon'} />
          </a>
          <a href={contactsURL()} className={'f_card'}>
            <img className={'f_img'} src={`https://${config.DOMAIN_AVATARS}/theme/white_soft/app/contact.png`} alt='logo' />
            <span className={'f_container'}>
              <p className={'f_heading'}>Lenza Contacts</p>
              <p className={'f_description'}>{t('auth.description.view_and_add_contacts')}</p>
            </span>
            <ChevronRight className={'f_icon'} />
          </a>
        </div>
      </div>
    </>
  )
}

export default Finish
