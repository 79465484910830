import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import App from './app/app'
import './app/styles/app.scss'
import '@clout-team/web-components/build/index.css'
import { BrowserRouter } from 'react-router-dom'
import store from './app/store'
import { Provider } from 'react-redux'
import ErrorBoundary from './app/ErrorBoundary/ErrorBoundary'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import jsonData from '../package.json'
import './shared/config/i18n/i18n'
import Loading from './components/Loading/Loading'
import PageRedirect from './components/PageRedirect/PageRedirect'
import { config, isAndroidApp, isIosApp } from '@clout-team/web-components'
import { isMobile } from '@clout-team/helpers'
import { IS_LOCALHOST } from './shared/const/constants'

/*if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://d1dba62f58cc42179d761b5a47506a7f@error.lenzaos.com/5',
    release: `${jsonData.version}`,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
  })
}*/

const { IS_AUTH_MODULE } = config
const showPageRedirect =
  !IS_LOCALHOST && IS_AUTH_MODULE && isMobile() && !isIosApp() && !isAndroidApp()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <BrowserRouter basename={'/'}>
    <Suspense fallback={<Loading showText={false} />}>
      {showPageRedirect && <PageRedirect />}
      <Provider store={store}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Provider>
    </Suspense>
  </BrowserRouter>,
)

/*document.addEventListener('DOMContentLoaded', () => {
  try {
    const IS_TEST_BOT = navigator?.userAgent?.includes('LenzaTest') ?? false;
    if (isIosApp() || isAndroidApp() || config.IS_LOCALHOST || IS_TEST_BOT) return
    if (isIphone() || isIpad()) {
      window.location.href = 'https://apps.apple.com/app/lenza/id1588741828';
      //Ios only
      window.open(config.IS_DEVELOP ? 'com.mailchat.clout-dev://' : 'com.mailchat.clout://', '_system');
      setTimeout(() => {
        if (confirm('You do not seem to have Lenza installed, do you want to go download it now?')) {
          window.location.replace('https://play.google.com/store/apps/details?id=com.mailchat.clout');
        }
      }, 3000);
      return
    }
    if (isAndroid()) {
      window.location.href = 'market://details?id=?id=com.mailchat.clout';
      //Android only
      window.open(config.IS_DEVELOP ? 'com.mailchat.clout_dev://' : 'com.mailchat.clout://', '_system');
       setTimeout(function() {
         if (confirm('You do not seem to have Lenza installed, do you want to go download it now?')) {
           window.location.replace('https://play.google.com/store/apps/details?id=com.mailchat.clout');
         }
       }, 3000);
    }
  } catch (e) {
    console.log(e)
  }
}, { once: true })*/
