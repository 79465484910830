import React from 'react'
import './Loading.scss'
import { ReactComponent as LenzaIconMob } from '../../assets/images/lenzaLogoMob.svg'
import { ReactComponent as LenzaIconWeb } from '../../assets/images/lenzaLogoWeb.svg'
import { useTranslation } from 'react-i18next'
import { config } from '@clout-team/web-components'

interface LoadingProps {
  showText?: boolean
}

const Loading = (props: LoadingProps) => {
  const { t } = useTranslation()

  const { showText = true } = props

  return (
    <div className={'l_loading'}>
      {config.IS_MOBILE ? <LenzaIconMob className={'l_loading__icon'} /> :
        <LenzaIconWeb className={'l_loading__icon'} />}
      {showText && <>
        <h2 className={'l_loading__heading'}>{t('auth.new.loading.title')}</h2>
        <p className={'l_loading__description'}>{t('auth.new.loading.description_starting')}</p>
      </>}
    </div>
  )
}

export default Loading
