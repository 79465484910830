import React, { useMemo } from 'react'
import './Header.scss'
import { ReactComponent as LenzaIconWeb } from '../../assets/images/lenzaLogoWeb.svg'
import { ReactComponent as LenzaIconMob } from '../../assets/images/lenzaLogoMob.svg'
import { BRAND_NAME, UI_TYPE } from '../../shared/const/constants'
import { config, Layout, sendToElectron, TrafficLights } from '@clout-team/web-components'
import LanguageSwitch from '../../shared/ui/LanguageSwitch/LanguageSwitch'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import { isElectron } from '@clout-team/helpers/dist/utils/web-utils'
import { useTranslation } from 'react-i18next'

const Header: React.FC = () => {
  const { t } = useTranslation()

  let os = useAppSelector((state) => state.ui.os)

  const renderTrafficLight = useMemo(() => {
    const onClose = () => sendToElectron('close-window')
    const onMinimize = () => sendToElectron('to-tray-window')
    const onMaximize = () => sendToElectron('max-window')

    return (
      <TrafficLights
        onClose={onClose}
        onMinimize={onMinimize}
        onMaximize={onMaximize}
        isWin={true}
      />
    )
  }, [])

  return (
    <div className={'hd_header'}>
      <Layout gap={'sm'} isAlignCenter className={'hd_header__logo'}>
        {config.IS_MOBILE ? (
          <LenzaIconMob className={'hd_header__icon'} />
        ) : (
          <LenzaIconWeb className={'hd_header__icon'} />
        )}

        <h1 className={'hd_header__heading'}>
          {BRAND_NAME} <span>{t('auth.title.authorization')}</span>
        </h1>
      </Layout>
      <LanguageSwitch />
      {isElectron() && os === UI_TYPE.WIN ? renderTrafficLight : <></>}
    </div>
  )
}

export default Header
