import { Checkbox } from '@clout-team/web-components'
import React, { useCallback, useState } from 'react'
import { useLoginActions } from '../../../state/slices/login'
import './Check.scss'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../shared/hooks/useAppSelector'

interface ICheck {
  corpDomains: string[]
  isMyDomain?: boolean
}

const Check = (props: ICheck) => {
  const { corpDomains, isMyDomain } = props

  const { t } = useTranslation()

  const { setIsMyDomainCorp, setIsDomainInvite } = useLoginActions()

  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [isShow, setIsShow] = useState<boolean>(false)
  const companyName = useAppSelector((state) => state.login.company_name)

  const onChangeValue = (value: boolean) => {
    setIsChecked(value)
    if (isMyDomain) {
      setIsMyDomainCorp(value)
    } else setIsDomainInvite(value)
  }

  const onCopyHandler = useCallback(() => {
    navigator.clipboard.writeText(`https://${companyName}.lenzaos.com`)
    setIsShow(true)
    setTimeout(() => {
      setIsShow(false)
    }, 700)
  }, [isShow, companyName])

  const domainsText = t('auth.new.check.domains').split('{__domains__}')

  return corpDomains.length > 0 ? (
    <div className={'ch_check'}>
      <div className={'ch_check__top'}>
        <p className={'ch_check__emoji'}>📫</p>
        <p className={'ch_check__top_description'}>{t('auth.new.invite.banner')}</p>
      </div>
      <div className={'ch_check__bot'}>
        <div className={'ch_check__checkbox'}>
          <Checkbox isChecked={isChecked} onChangeValue={onChangeValue} />
        </div>
        <div className={'ch_check__bot_wrapper'}>
          <p className={'ch_check__bot_label'}>{t('auth.new.invite.checkbox_domains')}</p>
          <p className={'ch_check__bot_description'}>
            {domainsText[0]}
            {corpDomains.map((el, index) => (
              <b key={index}>{'@' + el + ' '}</b>
            ))}
            {domainsText[1]}
            {isChecked && (
              <>
                {/*{' '}{L('auth.new.invite.checkbox_copylink_additional')?.replace('{__link__}', '')}{' '}
                <span onClick={onCopyHandler}>
                  {L('auth.new.invite.checkbox_copylink')}
                  {isShow && <Tooltip className={'ch_check__bot_tooltip'} title={L('auth.new.invite.tooltip')} />}
                </span>*/}
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  ) : null
}

export default Check
