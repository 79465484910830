import { DialogTitle } from '@clout-team/web-components'
import classNames from 'classnames'
import './MobileHeader.scss'
import { ReactNode } from 'react'

interface MobileHeaderProps {
  title?: string
  classNameTitle?: string
  onClose?: () => void
  subtitle?: string | JSX.Element
  rightButton?: {
    icon: ReactNode
    onClick?: () => void
  }
  isDismiss?: boolean
}

const MobileHeader = (props: MobileHeaderProps) => {
  const { title, classNameTitle, onClose, subtitle, rightButton, isDismiss } = props

  return (
    <DialogTitle
      noSwipeOnClose
      isDismiss={isDismiss}
      withOutPadding
      title={title}
      subtitle={subtitle}
      firstBtn={rightButton}
      classNameTitle={classNames('mobile-header', classNameTitle, {
        'mobile-header_hide-btn': !onClose,
      })}
      onClose={onClose}
    />
  )
}

export default MobileHeader
