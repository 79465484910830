import { Cookies } from 'react-cookie'
import { IS_LOCALHOST, REFERRERS, SINGLE_THEMES } from '../const/constants'
import { config, getSystemTheme } from '@clout-team/web-components'
import { isElectron } from '@clout-team/helpers/dist/utils/web-utils'
import { isMobile } from '@clout-team/helpers'
import { profileGet } from '../requests'
import { binarySearch } from '../const/emails'
import { AuthProfile } from '../../state/slices/login'

const { MONTH_MLS } = config
const cookies = new Cookies()

export const cookieOptions = IS_LOCALHOST
  ? {
      // eslint-disable-next-line no-use-before-define
      domain: location.hostname,
      maxAge: MONTH_MLS,
      path: '/',
    }
  : {
      domain: '.lenzaos.com',
      maxAge: MONTH_MLS,
      path: '/',
    }

export const removeCookie = (key: string) => {
  cookies.remove(key, { path: '/' })
}

export const isCorpEmail = (email: string) => {
  const res = binarySearch(getDomainFromEmail(email))
  return res === -1 && email !== undefined
}

export const setCookie = (key: string, val: string) => {
  cookies.set(key, val, cookieOptions)
}

export const setCookieAccountToken = (props: {
  token: string
  domain: string
  email: string
  userHash: string
}) => {
  const { token, domain, email, userHash } = props

  if (token && userHash) {
    setCookie('token', token)
    setCookie(`user-${userHash}`, token)
  }
  // вид левой панели по дефолту для веба для новых юзеров
  // const settings = getUserSettings();
  // if (!settings && window.globalAPI) {
  //   window.globalAPI().profile().setChatListView({view: isMobile() ? CHATS_LEFT_PANEL_STYLE.DEFAULT : CHATS_LEFT_PANEL_STYLE.FOLDER_VIEW})
  // }
  //
  // // сохраним тему в профиль
  // if (window.theme && window.globalAPI) {
  //   const theme = THEMES_LIST.find(theme => theme.slug === window.theme);
  //   if (theme) window.globalAPI().profile().setTheme({id: theme.id})
  // }
}

export const getCurrentUserFromWorkspaces = (workspaces: any) => {
  const currentToken = cookies.get('token')
  const userHash = cookies.get('active-hash')

  if (workspaces.length > 0) {
    // ищем по хэшу если это авторизация
    if (userHash) {
      const user = workspaces.find((t: any) => t.hash === userHash)
      removeCookie('active-hash')
      if (user) return user
    } else if (currentToken) {
      const currentUser = workspaces.find((t: any) => t.token === currentToken)
      removeCookie('active-hash')
      if (currentUser) {
        return currentUser
      }
    } else removeCookie('active-hash')
  }
  removeCookie('active-hash')
  return null
}

const getInviteData = (usersArr: any, inviteKey: string) => {
  const activeInviteHash = cookies.get(inviteKey)
  if (activeInviteHash && usersArr) {
    const arr = activeInviteHash.split(',')
    removeCookie(inviteKey)
    if (arr[0] && arr[1] && usersArr[arr[0]]) {
      const currentInvite = usersArr[arr[0]].find((u: any) => u.hash === arr[1])
      if (currentInvite) {
        return { ...currentInvite, email: arr[0] }
      }
    }
  }
  removeCookie(inviteKey)
  return null
}

export const getInviteFromCookie = (invited: any) => {
  return getInviteData(invited, 'active-invite')
}

export const getInviteDomainFromCookie = (allowed: any) => {
  return getInviteData(allowed, 'invite-domain')
}

export const checkToken = () => {
  return cookies.get('token')
}

export const getAuthUserFromWorkspaces = (
  workspaces: AuthProfile[],
): AuthProfile | null => {
  const token = cookies.get('token')
  const userHash = cookies.get('active-hash')
  if (workspaces.length > 0) {
    if (userHash) {
      const user = workspaces.find((t: any) => t.hash === userHash)
      removeCookie('active-hash')
      return user ?? null
    } else if (token) {
      const currentUser = workspaces.find((t: any) => t.token === token)
      return currentUser ?? null
    }
    return null
  }
  removeCookie('active-hash')
  return null
}

export const getCookieAccounts = async () => {
  const acc = cookies.get('accounts')
  const users: any[] = []
  if (acc && Object.keys(acc).length > 0) {
    for (const key of Object.keys(acc)) {
      try {
        const profile: any = await profileGet(acc[key].token)
        if (profile.response) {
          profile.response.token = acc[key].token
          users.push(profile.response)
          setCookie(`user-${profile.response.hash}`, acc[key].token)
        }
      } catch (e) {
        console.log(e)
      }
    }
    removeCookie('accounts')
  } else {
    const allCookie = cookies.getAll()
    for (const key of Object.keys(allCookie)) {
      if (key.indexOf('user-') > -1) {
        try {
          const profile: any = await profileGet(allCookie[key])
          if (profile.response) {
            profile.response.token = allCookie[key]
            users.push(profile.response)
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
  return users
}

export const getDomainFromEmail = (email: string) => {
  return (/@.+$/m.exec(email) as any)[0].replace('@', '')
}

export const changeLangUrl = (locale: string) => {
  const pathName = document.location.pathname.replace(locale, cookies.get('lang'))
  return pathName + document.location.search
}

export const getCookieLang = () => cookies.get('lang')

export const getReferrer = () => {
  const isElectronApp = cookies.get('isApp')

  if (
    (isElectron() && isElectronApp && isElectronApp === REFERRERS.CHATS) ||
    isMobile()
  ) {
    return REFERRERS.CHATS
  }

  if (document.referrer.indexOf(config.DOMAIN_CHAT) > -1) {
    return REFERRERS.CHATS
  }
  else if (document.referrer.indexOf('space.lenzaos.com') > -1) {
    return REFERRERS.SPACES
  }
  else if (document.referrer.indexOf('contacts.lenzaos.com') > -1) {
    return REFERRERS.CONTACTS
  }
  else return ''
}

export const trimPaste = (e: any) => {
  e.stopPropagation()
  e.preventDefault()
  // @ts-ignore
  const clipboardData = e.clipboardData || window.clipboardData,
    pastedData = clipboardData.getData('Text'),
    value = e.target.value
  e.target.value = pastedData.trim()
}

export function isInnerIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return false
  }
}

export function initTheme(name: string) {
  try {
    if (name) {
      const newTheme = SINGLE_THEMES.find((theme) => theme.name === name)

      if (newTheme) {
        SINGLE_THEMES.forEach((theme) =>
          document.body.classList.remove(`theme-${theme.index}`),
        )

        if (!document.body.classList.contains(`theme-${newTheme.index}`)) {
          document.body.classList.add(`theme-${newTheme.index}`)
        }
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function initColorMode(mode: string) {
  if (mode) {
    const isAuto = mode === 'AUTO'
    const newMode = isAuto ? getSystemTheme() : mode?.toLowerCase()

    document.body.classList.remove(`light-theme`, 'dark-theme')
    document.body.classList.add(`${newMode}-theme`)
  }
}

export function capitalize(value: string) {
  if (!value) return value
  return value[0].toUpperCase() + value.slice(1)
}
