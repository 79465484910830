import { IS_LOCALHOST, REFERRERS } from '../const/constants'
import { isElectron } from '@clout-team/helpers/dist/utils/web-utils'
import { Cookies } from 'react-cookie'
import { sendToElectron } from '@clout-team/web-components'
import { isIosApp } from '@clout-team/web-components'
import { isAndroidApp } from '@clout-team/web-components'
import { config } from '@clout-team/web-components'

const cookies = new Cookies()

export const chatURL = (company = '', token?: string) => {
  return `https://${config.DOMAIN_CHAT}/${company}?t=${token}`
}

export const spaceURL = () => `${process.env.REACT_APP_SPACE_URL}`
export const meetURL = () => `${process.env.REACT_APP_MEET_URL}`
export const contactsURL = () => `${process.env.REACT_APP_CONTACTS_URL}`

export const referrerRedirect = (
  referrer: string,
  company?: string,
  token: string = cookies.get('token'),
) => {
  if (referrer === REFERRERS.CHATS) {
    if (isElectron() && window.appAddAccount) {
      sendToElectron(config.windowsMessagesKeys.ADD_VIEW_ACCOUNT_KEY, { token: token })
      return
    }

    if ((isIosApp() || isAndroidApp() || IS_LOCALHOST) && window.appAddMobile) {
      window?.top?.postMessage(
        { type: config.windowsMessagesKeys.ADD_VIEW_ACCOUNT_KEY, token },
        '*',
      )
      return
    }

    if (IS_LOCALHOST) {
      document.location.href = `http://localhost:4001/${company}?t=${token}`
    } else {
      document.location.href = chatURL(company, token)
    }
  }
}
